// src/pages/Home.js
import React, { useState } from 'react';
import { usePatterns } from '../hooks/usePatterns';
import HeroSection from '../components/hero/HeroSection';
import CategoryFilters from '../components/patterns/CategoryFilters';
import PatternGrid from '../components/patterns/PatternGrid';
import PatternModal from '../components/patterns/PatternModal';

const Home = () => {
  const {
    patterns,
    categories,
    selectedCategory,
    setSelectedCategory,
    loading,
    error
  } = usePatterns();

  const [selectedPattern, setSelectedPattern] = useState(null);

  const handlePatternClick = (pattern) => {
    console.log('Opening modal for pattern:', pattern); // Debug log
    setSelectedPattern(pattern);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#131316] flex items-center justify-center">
        <div className="text-white text-xl">Loading patterns...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#131316] flex items-center justify-center">
        <div className="text-red-500 text-xl">Error loading patterns: {error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#131316]">
      <HeroSection />
      
      <main className="max-w-7xl mx-auto">
        <CategoryFilters
          categories={categories}
          activeCategory={selectedCategory}
          onCategoryChange={setSelectedCategory}
        />

        <div>
          <h2 className="text-2xl font-bold text-white mb-6">
            {selectedCategory === 'All' ? 'All Patterns' : `${selectedCategory} Patterns`}
          </h2>
          <PatternGrid 
            patterns={patterns}
            onPatternClick={handlePatternClick}  // Pass the callback
          />
        </div>
      </main>

      {selectedPattern && (
        <PatternModal
          pattern={selectedPattern}
          onClose={() => setSelectedPattern(null)}
        />
      )}
    </div>
  );
};

export default Home;
