// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { ArrowLeft } from 'lucide-react';
// import { fetchSheetData } from '../../utils/sheetsHelper';

// const PatternDetail = () => {
//  const { company, category, interaction } = useParams();
//  const navigate = useNavigate();
//  const location = useLocation();
//  const [pattern, setPattern] = useState(null);
//  const [loading, setLoading] = useState(true);
//  const [logoError, setLogoError] = useState(false);

//  useEffect(() => {
//    const loadPattern = async () => {
//      const data = await fetchSheetData();
//      const foundPattern = data.find(p => 
//        p.Company.toLowerCase().replace(/\s+/g, '-') === company &&
//        p['Interaction category'].toLowerCase().replace(/\s+/g, '-') === category &&
//        p['Interaction name'].toLowerCase().replace(/\s+/g, '-') === interaction
//      );
//      setPattern(foundPattern);
//      setLoading(false);
//    };

//    loadPattern();
//  }, [company, category, interaction]);

//  const getVideoEmbedUrl = (url) => {
//    if (url.includes('vimeo.com')) {
//      const vimeoId = url.match(/vimeo\.com\/(\d+)/)[1];
//      return `https://player.vimeo.com/video/${vimeoId}?h=556efd5208&autoplay=1&loop=1&byline=0&title=0&controls=1&quality=1080p&portrait=0&sidedock=0&collections=0&watch_later=0&share=0`;
//    }
   
//    // For Google Drive
//    const matches = url.match(/\/d\/(.+?)\/view/);
//    const fileId = matches ? matches[1] : null;
//    return fileId ? `https://drive.google.com/file/d/${fileId}/preview?hq=true` : url;
//  };

//  const getClearbitLogoUrl = (company) => {
//    return `https://logo.clearbit.com/${company.toLowerCase().replace(/\s+/g, '')}.com`;
//  };

//  const getCompanyInitials = (company) => {
//    return company
//      .split(' ')
//      .map(word => word[0])
//      .join('')
//      .toUpperCase()
//      .slice(0, 2);
//  };

//  if (loading) return <div className="text-white p-8">Loading...</div>;
//  if (!pattern) return <div className="text-white p-8">Pattern not found</div>;

//  return (
//    <div className="max-w-[1000px] mx-auto px-4 pt-24">
//      {/* Back Navigation */}
//      <div className="flex items-center justify-between mb-8 w-full px-12">
//        <div className="flex items-center gap-2">
//          <button 
//            onClick={() => navigate(location.state?.from || '/')}
//            className="flex items-center gap-2 text-gray-400 hover:text-white"
//          >
//            <ArrowLeft size={16} />
//          </button>
//          <div className="flex items-center gap-2">
//            <div className="w-6 h-6 rounded bg-[#1A1A1A] flex items-center justify-center overflow-hidden">
//              {logoError ? (
//                <span className="text-xs font-medium">{getCompanyInitials(pattern.Company)}</span>
//              ) : (
//                <img
//                  src={getClearbitLogoUrl(pattern.Company)}
//                  alt={pattern.Company}
//                  className="w-full h-full object-contain p-1"
//                  onError={() => setLogoError(true)}
//                />
//              )}
//            </div>
//            <span className="text-gray-400 text-sm">
//              {pattern.Company} / {pattern['Interaction name']}
//            </span>
//          </div>
//        </div>
//        <span className="text-gray-400 font-['JetBrains_Mono'] text-sm">
//          #{pattern['Serial number']}
//        </span>
//      </div>

//      <div className="w-full space-y-8">
//        {/* Video */}
//        <div className="relative aspect-video w-full rounded-lg overflow-hidden">
//          <iframe
//            src={getVideoEmbedUrl(pattern['Interaction link'])}
//            className="absolute inset-0 w-full h-full"
//            frameBorder="0"
//            allowFullScreen
//            allow="autoplay; fullscreen; picture-in-picture"
//          />
//        </div>
//        </div>

//        <div className="w-full space-y-8 mx-12 mt-12">

//        {/* Pattern Info */}
//        <div className="grid grid-cols-3 gap-8">
//          <div>
//            <span className="text-gray-400 text-sm block mb-1">Device</span>
//            <span className="text-white text-sm">{pattern.Device}</span>
//          </div>
//          <div>
//            <span className="text-gray-400 text-sm block mb-1">Category</span>
//            <span className="text-white text-sm">{pattern['Interaction category']}</span>
//          </div>
//          <div>
//            <span className="text-gray-400 text-sm block mb-1">Industry</span>
//            <span className="text-white text-sm">{pattern.Industry}</span>
//          </div>
//        </div>

//        {/* Tags */}
//        <div>
//          <span className="text-gray-400 text-sm block mb-1">Tags</span>
//          <div className="flex items-center gap-2">
//            {pattern.Tags.map((tag, index) => (
//              <React.Fragment key={index}>
//                <span className="text-sm text-gray-400">#{tag}</span>
//                {index !== pattern.Tags.length - 1 && (
//                  <span className="text-gray-400">•</span>
//                )}
//              </React.Fragment>
//            ))}
//          </div>
//        </div>

//        {/* UX Problem */}
//        <div>
//          <span className="text-gray-400 text-sm block mb-1">UX Problem solved</span>
//          <p className="text-white text-sm">{pattern['UX problem solved']}</p>
//        </div>

//        {/* Commentary */}
//        <div>
//          <span className="text-gray-400 text-sm block mb-1">Commentary</span>
//          <p className="text-white text-sm">{pattern.Commentary}</p>
//        </div>

//        {/* Related Patterns */}
//        <div>
//          <span className="text-gray-400 text-sm block mb-4">Related patterns</span>
//          <div className="grid grid-cols-2 gap-4">
//            {/* Add related patterns cards here */}
//          </div>
//        </div>
//      </div>
//    </div>
//  );
// };

// export default PatternDetail;

// src/components/PatternDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { fetchSheetData } from '../../utils/sheetsHelper';

const PatternDetail = () => {
  const { company, category, interaction } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [pattern, setPattern] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoError, setLogoError] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [videoLoading, setVideoLoading] = useState(true);

  useEffect(() => {
    const loadPattern = async () => {
      try {
        const data = await fetchSheetData();
        const foundPattern = data.find(p => 
          p.Company.toLowerCase().replace(/\s+/g, '-') === company &&
          p['Interaction category'].toLowerCase().replace(/\s+/g, '-') === category &&
          p['Interaction name'].toLowerCase().replace(/\s+/g, '-') === interaction
        );
        setPattern(foundPattern);
      } catch (error) {
        console.error('Error loading pattern:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPattern();
  }, [company, category, interaction]);

  const getVideoEmbedUrl = (url, isPreview = false) => {
    // If url is null, undefined, or empty string, return empty string
    if (!url) return '';
    
    // Check if it's a Vimeo URL and has the correct pattern
    if (url.includes('vimeo.com')) {
      // Try to extract the video ID
      const vimeoMatch = url.match(/vimeo\.com\/(\d+)/);
      const vimeoId = vimeoMatch ? vimeoMatch[1] : null;
      
      // If we couldn't extract a video ID, return the original URL
      if (!vimeoId) return url;
      
      // Build the embed URL with parameters
      const params = new URLSearchParams({
        autoplay: isPreview ? '0' : '1',
        loop: '1',
        byline: '0',
        title: '0',
        muted: isPreview ? '1' : '0',
        controls: isPreview ? '0' : '1',
        quality: isPreview ? '360p' : '1080p',
        portrait: '0',
        sidedock: '0',
        collections: '0',
        watch_later: '0',
        share: '0'
      });

      return `https://player.vimeo.com/video/${vimeoId}?${params.toString()}`;
    }
    
    return url;
  };

  const getClearbitLogoUrl = (companyName) => {
    if (!companyName) return '';
    return `https://logo.clearbit.com/${companyName.toLowerCase().replace(/\s+/g, '')}.com`;
  };

  const getCompanyInitials = (companyName) => {
    if (!companyName) return '';
    return companyName
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const renderSelectedMedia = () => {
    if (!pattern) return null;

    if (selectedMediaIndex === 0 && pattern['Interaction link']) {
      return (
        <div className="relative w-full h-full">
          {videoLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black">
              <div className="animate-pulse text-gray-400">Loading video...</div>
            </div>
          )}
          <iframe
            src={getVideoEmbedUrl(pattern['Interaction link'])}
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; fullscreen; picture-in-picture"
            onLoad={() => setVideoLoading(false)}
          />
        </div>
      );
    }

    if (pattern.screenshots && pattern.screenshots.length > 0) {
      const screenshotIndex = selectedMediaIndex - 1;
      const screenshot = pattern.screenshots[screenshotIndex];
      
      if (screenshot) {
        return (
          <img
            src={screenshot}
            alt={`${pattern['Interaction name']} - Screenshot ${screenshotIndex + 1}`}
            className="absolute inset-0 w-full h-full object-contain bg-black"
          />
        );
      }
    }

    return (
      <div className="absolute inset-0 flex items-center justify-center bg-black text-gray-400">
        No media available
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  if (!pattern) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-white">Pattern not found</div>
      </div>
    );
  }

  const hasScreenshots = pattern.screenshots && pattern.screenshots.length > 0;

  return (
    <div className="min-h-screen bg-black">
      <div className="max-w-[1000px] mx-auto px-4 pt-24 pb-12">
        {/* Back Navigation */}
        <div className="flex items-center justify-between mb-8 w-full px-12">
          <div className="flex items-center gap-2">
            <button 
              onClick={() => navigate(location.state?.from || '/')}
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
            >
              <ArrowLeft size={16} />
            </button>
            <div className="flex items-center gap-2">
              <div className="w-6 h-6 rounded bg-[#1A1A1A] flex items-center justify-center overflow-hidden">
                {logoError ? (
                  <span className="text-xs font-medium">{getCompanyInitials(pattern.Company)}</span>
                ) : (
                  <img
                    src={getClearbitLogoUrl(pattern.Company)}
                    alt={pattern.Company}
                    className="w-full h-full object-contain p-1"
                    onError={() => setLogoError(true)}
                  />
                )}
              </div>
              <span className="text-gray-400 text-sm">
                {pattern.Company} / {pattern['Interaction name']}
              </span>
            </div>
          </div>
          <span className="text-gray-400 font-['JetBrains_Mono'] text-sm">
            #{pattern['Serial number']}
          </span>
        </div>

        <div className="w-full space-y-4">
          {/* Main Media Display */}
          <div className="relative aspect-video w-full rounded-lg overflow-hidden bg-black">
            {renderSelectedMedia()}
          </div>

          {/* Media Selector */}
          {hasScreenshots && (
            <div className="flex gap-2 mt-4 px-12">
              {/* Video Thumbnail */}
              {pattern['Interaction link'] && (
                <button
                  onClick={() => setSelectedMediaIndex(0)}
                  className={`relative aspect-video w-32 rounded-md overflow-hidden transition-all ${
                    selectedMediaIndex === 0 
                      ? 'ring-2 ring-white' 
                      : 'opacity-50 hover:opacity-100'
                  }`}
                >
                  <iframe
                    src={getVideoEmbedUrl(pattern['Interaction link'], true)}
                    className="absolute inset-0 w-full h-full pointer-events-none"
                    frameBorder="0"
                  />
                </button>
              )}

              {/* Screenshot Thumbnails */}
              {pattern.screenshots.map((screenshot, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedMediaIndex(index + 1)}
                  className={`relative aspect-video w-32 rounded-md overflow-hidden transition-all ${
                    selectedMediaIndex === index + 1 
                      ? 'ring-2 ring-white' 
                      : 'opacity-50 hover:opacity-100'
                  }`}
                >
                  <img
                    src={screenshot}
                    alt={`Screenshot ${index + 1}`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="w-full space-y-8 mx-12 mt-12">
          {/* Pattern Info */}
          <div className="grid grid-cols-3 gap-8">
            <div>
              <span className="text-gray-400 text-sm block mb-1">Device</span>
              <span className="text-white text-sm">{pattern.Device}</span>
            </div>
            <div>
              <span className="text-gray-400 text-sm block mb-1">Category</span>
              <span className="text-white text-sm">{pattern['Interaction category']}</span>
            </div>
            <div>
              <span className="text-gray-400 text-sm block mb-1">Industry</span>
              <span className="text-white text-sm">{pattern.Industry}</span>
            </div>
          </div>

          {/* Tags */}
          {pattern.Tags && pattern.Tags.length > 0 && (
            <div>
              <span className="text-gray-400 text-sm block mb-1">Tags</span>
              <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                {pattern.Tags.map((tag, index) => (
                  <React.Fragment key={index}>
                    <span className="text-sm text-gray-400">#{tag}</span>
                    {index !== pattern.Tags.length - 1 && (
                      <span className="text-gray-600">•</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}

          {/* UX Problem */}
          {pattern['UX Problem solved'] && (
            <div>
              <span className="text-gray-400 text-sm block mb-1">UX Problem solved</span>
              <p className="text-white text-sm">{pattern['UX Problem solved']}</p>
            </div>
          )}

          {/* Commentary */}
          {pattern.Commentary && (
            <div>
              <span className="text-gray-400 text-sm block mb-1">Commentary</span>
              <p className="text-white text-sm whitespace-pre-wrap">{pattern.Commentary}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternDetail;