// src/components/PatternCard.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PatternCard = ({ pattern, onPatternClick }) => {
  const [logoError, setLogoError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getVideoEmbedUrl = (url, isPreview = false) => {
    // If url is null, undefined, or empty string, return empty string
    if (!url) return '';
    
    // Check if it's a Vimeo URL and has the correct pattern
    if (url.includes('vimeo.com')) {
      // Try to extract the video ID
      const vimeoMatch = url.match(/vimeo\.com\/(\d+)/);
      const vimeoId = vimeoMatch ? vimeoMatch[1] : null;
      
      // If we couldn't extract a video ID, return the original URL
      if (!vimeoId) return url;
      
      // Build the embed URL with parameters
      const params = new URLSearchParams({
        autoplay: isPreview ? '0' : '1',
        loop: '1',
        background: '1',     // Removes most player chrome
        byline: '0',         // Removes the author name
        title: '0',          // Removes the video title
        muted: isPreview ? '1' : '0',
        controls: isPreview ? '0' : '1',
        quality: isPreview ? '360p' : '1080p',
        portrait: '0',       // Removes the author portrait
        sidedock: '0',       // Removes the sidedock
        transparent: '1',    // Makes the background transparent
        collections: '0',
        watch_later: '0',
        share: '0'
      });
      return `https://player.vimeo.com/video/${vimeoId}?${params.toString()}`;
    }
    
    // For Google Drive
    if (url.includes('drive.google.com')) {
      const matches = url.match(/\/d\/(.+?)\/view/);
      const fileId = matches ? matches[1] : null;
      return fileId ? `https://drive.google.com/file/d/${fileId}/preview?hq=true` : url;
    }

    return url;
  };

  const getClearbitLogoUrl = (company) => {
    if (!company) return '';
    return `https://logo.clearbit.com/${company.toLowerCase().replace(/\s+/g, '')}.com`;
  };

  const handleLogoError = () => {
    setLogoError(true);
  };

  const getCompanyInitials = (company) => {
    if (!company) return '';
    return company
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const handleClick = () => {
    if (!pattern.Company || !pattern['Interaction category'] || !pattern['Interaction name']) return;
    
    const slug = `/${pattern.Company.toLowerCase().replace(/\s+/g, '-')}/${pattern['Interaction category'].toLowerCase().replace(/\s+/g, '-')}/${pattern['Interaction name'].toLowerCase().replace(/\s+/g, '-')}`;
    navigate(slug, { state: { from: location.pathname } });
  };

  if (!pattern) return null;

  return (
    <div onClick={handleClick} className="group relative border border-[#1A1A1A] bg-[#0D0D0F] rounded-xl overflow-hidden p-4 cursor-pointer">
      {/* Company Logo + Serial Number */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-lg bg-[#1A1A1A] flex items-center justify-center overflow-hidden">
            {logoError ? (
              <span className="text-white text-sm font-medium">
                {getCompanyInitials(pattern.Company)}
              </span>
            ) : (
              <img
                src={getClearbitLogoUrl(pattern.Company)}
                alt={pattern.Company}
                className="w-full h-full object-contain p-1.5"
                onError={handleLogoError}
              />
            )}
          </div>
          <span className="text-gray-300 font-medium font-['JetBrains_Mono']">{pattern.Company}</span>
        </div>
        <span className="text-gray-300 text-sm font-['JetBrains_Mono']">{pattern['Serial number']}</span>
      </div>

      {/* Video Container with Grayscale */}
      <div className="relative aspect-video rounded-sm overflow-hidden mb-4">
        <div className="relative w-full h-full group">
          {pattern['Interaction link'] && (
            <iframe
              src={getVideoEmbedUrl(pattern['Interaction link'], true)}
              className="absolute inset-0 w-full h-full"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; fullscreen; picture-in-picture"
            />
          )}
        </div>
      </div>

      {/* Title and Tags */}
      <div className="space-y-4">
        <h3 className="text-white text-md font-medium">
          {pattern['Interaction name']}
        </h3>

        <div className="flex flex-wrap gap-3">
          {pattern.Device && (
            <span className="text-xs px-3 py-1 bg-[#1A1A1A] text-gray-300 rounded-lg font-['JetBrains_Mono']">
              {pattern.Device}
            </span>
          )}
          {pattern['Interaction category'] && (
            <span className="text-xs px-3 py-1 bg-[#1A1A1A] text-gray-300 rounded-lg font-['JetBrains_Mono']">
              {pattern['Interaction category']}
            </span>
          )}
        </div>

        {/* Tags */}
        {pattern.Tags && pattern.Tags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {pattern.Tags.map((tag, index) => (
              <React.Fragment key={index}>
                <span className="text-xs px-3 py-1 bg-[#1A1A1A] text-gray-300 rounded-lg font-['JetBrains_Mono']">
                  #{tag}
                </span>
                {index !== pattern.Tags.length - 1 && (
                  <span className="text-gray-400">•</span>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatternCard;