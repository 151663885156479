import React from 'react';

const CategoryFilters = ({ categories, activeCategory, onCategoryChange }) => {
  return (
    <div className="mb-12">
      <div className="flex flex-wrap gap-3 justify-center">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => onCategoryChange(category)}
            className={`
              px-4 
              py-2 
              rounded-lg 
              text-sm 
              font-medium
              transition-colors
              ${
                activeCategory === category
                  ? 'bg-white text-black border border-[#080808]'
                  : 'border border-[#2A2929] bg-[#272729] text-white hover:bg-opacity-80'
              }
            `}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategoryFilters;