import React, { useState } from 'react';
import bgImage from '../../assets/images/bg.png';  // Adjust the path based on your component's location

const HeroSection = () => {
  const [email, setEmail] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      // Handle subscription logic here
      console.log('Subscribing email:', email);
      setEmail('');
    } catch (error) {
      console.error('Failed to subscribe:', error);
    }
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-[80vh] text-center px-4">
      {/* Background image */}
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />

      {/* Content overlay */}
      <div className="relative z-10">
        <p className="text-gray-400 uppercase tracking-wider text-xs mb-6 font-['JetBrains_Mono']">
          MOST COMPREHENSIVE LIBRARY
        </p>
        <h1 className="text-3xl md:text-5xl lg:text-6xl font-medium text-white mb-6 max-w-4xl leading-tight">
          Learn how great AI UX interactions work?
        </h1>

        <p className="text-gray-400 text-lg mb-8">
          Subscribe for bi-weekly drops
        </p>

        <form onSubmit={handleSubscribe} className="w-full max-w-md flex flex-col sm:flex-row gap-3 mx-auto">
          <input
            type="email"
            placeholder="Subscribe before AI takes this over..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="flex-1 px-4 py-2 rounded-lg bg-[#262629] text-white placeholder-gray-400 focus:outline-none"
            required
          />
          <button
            type="submit"
            className="px-6 py-2 bg-white text-black font-medium rounded-lg hover:bg-gray-100 transition-colors"
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default HeroSection;